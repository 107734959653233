<template>
    <div class="md-layout text-center">
        <div
            class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
            <login-card header-color="green">
                <h4 slot="title" class="title">Log in</h4>
                <p slot="description" class="description">Be Classical</p>
                <md-field class="md-form-group" slot="inputs" :class="getValidationClass('username')">
                    <md-icon>person</md-icon>
                    <label>Username...</label>
                    <md-input v-on:keyup.enter="login" id="username" name="username"  v-model="form.username" type="text"></md-input>
                </md-field>
                <md-field :class="getValidationClass('password')" slot="inputs">
                    <md-icon>lock_outline</md-icon>
                    <label>Password...</label>
                    <md-input v-on:keyup.enter="login" id="password" name="password" type="password" v-model="form.password"></md-input>
                </md-field>
                <md-button slot="footer" class="md-simple md-success md-lg" @click="login">
                    Lets Go
                </md-button>
            </login-card>
        </div>
    </div>
</template>
<script>
    import {LoginCard} from "../../../components";
    import Swal from "sweetalert2";
    import {validationMixin} from 'vuelidate';
    import {required} from 'vuelidate/lib/validators';

    export default {
        components: {
            LoginCard
        },
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    username: null,
                    password: null
                }
            };
        },
        validations: {
            form: {
                password: {
                    required
                },
                username: {
                    required
                }
            }
        },
        methods: {
            getValidationClass(fieldName) {
                const field = this.$v.form[fieldName];

                if (field) {
                    return {
                        'error-form': field.$invalid && field.$dirty
                    }
                }
            },
            login: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                Swal.fire({
                    title: 'Loading',
                    timerProgressBar: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    }
                });
                axios.default.post('/api/login', {
                    username: this.form.username,
                    password: this.form.password
                })
                    .then(response => {
                        console.log(response.data);
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            showConfirmButton: false,
                            timer: 1500
                        }).then(result => {
                            window.location.replace("/");
                        });
                    })
                    .catch(error => {
                        console.error(error);
                        Swal.fire({
                            icon: 'error',
                            title: "Oops...",
                            text: "Invalid Data"
                        });
                    });
            }
        }
    };
</script>

<style scoped>
    .error-form:after{
        background-color: #ff1744!important;
    }
</style>
