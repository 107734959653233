require('../bootstrap');
import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-login";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes-login";
import vuetify from '../plugins/vuetify';
import {asset} from '@codinglabs/laravel-asset';


// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);

// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
    linkExactActiveClass: "nav-item active"
});

// global library setup
Vue.prototype.$Chartist = Chartist;

Vue.mixin({
    methods: {
        asset: asset
    }
});
/* eslint-disable no-new */
new Vue({
    el: "#app",
    vuetify,
    render: h => h(App),
    router,
});
