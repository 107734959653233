<template>
    <div data-app class="v-application v-application--is-ltr theme--light"
         style="background-color: inherit;display:block">
        <router-view></router-view>
    </div>
</template>

<script>
import Vue from "vue";
import {asset} from "@codinglabs/laravel-asset";

export default {
    props: ['loggedInUser', 'exchangeRateUsdMarketProp', 'exchangeRateUsdBankProp', 'exchangeRateUsdOfficialProp'],
    created() {
        console.log('App created');
        console.log(this.loggedInUser);
        this.$store.commit('setUser', this.loggedInUser)
        this.$store.commit('setExchangeRateUsdMarket', {value:this.exchangeRateUsdMarketProp})
        this.$store.commit('setExchangeRateUsdBank', {value:this.exchangeRateUsdBankProp})
        this.$store.commit('setExchangeRateUsdOfficial', {value:this.exchangeRateUsdOfficialProp})
        const loggedInUser = this.loggedInUser;
        Vue.mixin({
            data() {
                return {
                    user: loggedInUser,
                    currencies: [
                        {
                            name: 'usd',
                            displayName: 'USD',
                            displaySymbol: '$'
                        },
                        {
                            name: 'lbp',
                            displayName: 'LBP',
                            displaySymbol: 'LBP'
                        },
                    ],
                };
            },
            methods: {
                asset: asset,
                can: function (permission) {
                    for (let i = 0; i < this.user.permissions.length; i++) {
                        if (this.user.permissions[i].key === permission) {
                            return true
                        }
                    }
                    return false;
                },
                setExchangeRateUsdMarket(value){this.$store.commit('setExchangeRateUsdMarket', {value:value})},
                setExchangeRateUsdBank(value){this.$store.commit('setExchangeRateUsdBank', {value:value})},
                setExchangeRateUsdOfficial(value){this.$store.commit('setExchangeRateUsdOfficial', {value:value})},
                castTimestamps(obj) {
                    let date = new Date(obj.created_at);
                    obj.created_at = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.toLocaleTimeString()}`;

                    date = new Date(obj.updated_at);
                    obj.updated_at = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.toLocaleTimeString()}`;
                }
            }
        });
    }
};
</script>
