import GlobalComponents from "./globalComponents-login";
import SideBar from "./components/SidebarPlugin";
// Tabs plugin. Used on Panels page.

import VueMaterial from "vue-material";

// asset imports
// import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";
// import "./assets/css/demo.css";

// library auto imports
import "es6-promise/auto";

export default {
    install(Vue) {
        Vue.use(GlobalComponents);
        // Vue.use(GlobalDirectives);
        Vue.use(VueMaterial);
        Vue.use(SideBar);
        // Vue.use(Notifications);
    }
};
