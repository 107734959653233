import { ZoomCenterTransition} from "vue2-transitions";
import Login from "./pages/Dashboard/Pages/Login.vue";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component("ZoomCenterTransition", ZoomCenterTransition);
        Vue.component("login-page", Login);
    }
};

export default GlobalComponents;
