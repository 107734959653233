import { render, staticRenderFns } from "./GlobalSalesCard.vue?vue&type=template&id=38620d90&scoped=true&"
import script from "./GlobalSalesCard.vue?vue&type=script&lang=js&"
export * from "./GlobalSalesCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38620d90",
  null
  
)

export default component.exports