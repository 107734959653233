import AuthLayout from "../pages/Dashboard/Pages/AuthLayout.vue";

// Pages
import Pricing from "../pages/Dashboard/Pages/Pricing.vue";
import Login from "../pages/Dashboard/Pages/Login.vue";
import Register from "../pages/Dashboard/Pages/Register.vue";
import Lock from "../pages/Dashboard/Pages/Lock.vue";

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        {
            path: "/login",
            name: "Login",
            component: Login
        },
    ]
};

const routes = [
    {
        path: "*",
        redirect: "/login",
        name: "Login"
    },
    {
        path: "/",
        redirect: "/login",
        name: "Login"
    },
    authPages
];

export default routes;
