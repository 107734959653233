<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                    <div class="card-icon">
                        <v-icon>{{ tableIcon }}</v-icon>
                    </div>
                    <h4 class="title">{{ tableName }}</h4>
                </md-card-header>
                <md-card-content>
                    <md-button v-if="canAdd" class="md-success md-just-icon" @click="add">
                        <md-icon>add</md-icon>
                        <md-tooltip md-direction="bottom">Add</md-tooltip>
                    </md-button>
                    <md-button v-if="isPrintable && canPrint" class="md-primary md-just-icon" @click="print">
                        <md-icon>print</md-icon>
                        <md-tooltip md-direction="bottom">Print</md-tooltip>
                    </md-button>
                    <md-button class="md-info md-just-icon" @click="refresh">
                        <md-icon>sync</md-icon>
                        <md-tooltip md-direction="bottom">Refresh</md-tooltip>
                    </md-button>
                    <md-table
                        :value="tableData"
                        :md-sort.sync="currentSort"
                        :md-sort-order.sync="currentSortOrder"
                        :md-sort-fn="customSort"
                        class="paginated-table table-striped table-hover"
                    >
                        <md-table-toolbar>
                            <md-field>
                                <label>Per page</label>
                                <md-select v-model="pagination.perPage" name="pages">
                                    <md-option
                                        v-for="item in pagination.perPageOptions"
                                        :key="item"
                                        :label="item"
                                        :value="item"
                                    >
                                        {{ item }}
                                    </md-option>
                                </md-select>
                            </md-field>
                            <md-field v-if="isSearchable == null || isSearchable">
                                <md-input
                                    type="search"
                                    class="mb-3"
                                    clearable
                                    style="width: 200px"
                                    placeholder="Search records"
                                    v-model="searchQuery"
                                    v-on:keyup.enter="searched"
                                >
                                </md-input>
                                <md-button class="md-success md-just-icon" @click="searched">
                                    <md-icon>search</md-icon>
                                    <md-tooltip md-direction="bottom">Search</md-tooltip>
                                </md-button>
                            </md-field>

                        </md-table-toolbar>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell v-for="(header, index) in headers" :key="header.slug" :md-label="header.name"
                                           :md-sort-by="!header.noSort ? header.sortSlug ? header.sortSlug : header.slug : null">
                                <template v-if="header.type === 'formattedNumber'">
                                    <span v-html="getSlug(item, header) ? getSlug(item, header).toLocaleString() : ''"></span>
                                </template>
                                <template v-else-if="header.type === 'checkbox'">
                                    <v-checkbox style="margin:0 0 0 0" v-model="item[header.slug]"
                                                @click.native="header.clickAction(item)" color="green" hide-details
                                                readonly></v-checkbox>
                                </template>
                                <template v-else-if="header.type === 'image'">
                                    <v-img :src="getSlug(item, header) || 'https://www.centerforempathy.org/wp-content/uploads/2019/11/placeholder.png'" width="70px" height="70px" contain></v-img>
                                </template>
                                <template v-else>{{ getSlug(item, header) }}</template>
                            </md-table-cell>
                            <md-table-cell v-if="withActions == null || withActions" md-label="Actions">
                                <v-btn v-for="(action, index) in actions"
                                       icon
                                       :key="index"
                                       @click.native="action.action(item)"
                                       :style="action.style">
                                    <v-icon>{{ action.icon }}</v-icon>
                                </v-btn>
                                <!--                                <md-button v-for="(action, index) in actions"-->
                                <!--                                           :key="index"-->
                                <!--                                           :class="action.styleClass"-->
                                <!--                                           @click.native="action.action(item)"-->
                                <!--                                           style="margin: 1px">-->
                                <!--                                    <v-icon>{{ action.icon }}</v-icon>-->
                                <!--                                </md-button>-->
                            </md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-card-content>
                <md-card-actions md-alignment="space-between">
                    <div class="">
                        <p class="card-category">
                            Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
                        </p>
                    </div>
                    <pagination
                        class="pagination-no-border pagination-success"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total"
                    >
                    </pagination>
                </md-card-actions>
            </md-card>
        </div>
    </div>
</template>

<script>
import Fuse from "fuse.js";
import Swal from "sweetalert2";

export default {
    name: "PaginatedTable",
    props: ['headers', 'actions', 'tableName', 'tableIcon', 'initialPagination', 'canAdd', 'canPrint', 'isPrintable', 'isSearchable', 'withActions'],
    computed: {
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = pagination.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        currentPage() {
            return this.pagination.currentPage;
        },
        perPage() {
            return this.pagination.perPage;
        },
        computedPagination() {
            return Object.assign({}, this.pagination);
        }
    },
    watch: {
        computedPagination: {
            handler: function (val, oldVal) {
                // console.log(`Old PerPage: ${oldVal.perPage}`);
                // console.log(`New PerPage: ${val.perPage}`);
                // console.log(`Old Current Page: ${oldVal.currentPage}`);
                // console.log(`New Current Page: ${val.currentPage}`);
                if (oldVal.perPage !== val.perPage && val.currentPage > 1 || oldVal.total !== val.total)
                    return;

                this.$emit('paginationChange', {value: val});
            },
            deep: true
        },
        isLoading(val) {
            if (val === true)
                Swal.fire({
                    title: 'Loading',
                    timerProgressBar: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    }
                });
            else
                Swal.close();
        }
    },
    data() {
        return {
            currentSort: "created_at",
            currentSortOrder: "desc",
            searchQuery: "",
            propsToSearch: ["name", "email", "age"],
            tableData: [],
            searchedData: [],
            fuseSearch: null,
            pagination: _.cloneDeep(this['initialPagination']),
            isLoading: false,
        };
    },
    methods: {
        setData(data) {
            this.tableData = data;
        },
        updateItem(item) {
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].id === item.id) {
                    this.tableData[i] = item;
                    this.$forceUpdate();
                    return;
                }
            }
            this.tableData.unshift(item);
        },
        customSort(value) {
            if (!this.currentSort || !this.currentSortOrder) {
                return;
            }
            console.log(value);
            this.$emit('onSort', {sortBy: this.currentSort, sortAs: this.currentSortOrder});
        },
        searched() {
            this.$emit('search', {
                value: this.searchQuery
            })
        },
        add() {
            this.$emit('onAddClick');
        },
        print() {
            this.$emit('onPrintClick');
        },
        refresh() {
            this.$emit('onRefreshClick');
        },
        getSlug(item, header) {
            let splitGlobalSlug = header.slug.split('|');
            if (splitGlobalSlug.length === 1) {
                return this.splitSlugByPlus(item, splitGlobalSlug[0], header);
            }
            for (let i = 0; i < splitGlobalSlug.length; i++) {
                const currentSlug = this.splitSlugByPlus(item, splitGlobalSlug[i], header);
                if (currentSlug != null && currentSlug !== '') {
                    return currentSlug;
                }
            }
            return '';
        },
        splitSlugByPlus(item, slug, header) {
            let splitSlug = slug.split('+');

            if (splitSlug.length === 1) {
                if (header.type === 'formattedNumber' && this.getSlugItem(item, slug) != null)
                    return this.getSlugItem(item, slug).toLocaleString();
                else
                    return this.getSlugItem(item, slug);
            }
            try {
                let outputItem;
                if (header.type === 'formattedNumber'  && this.getSlugItem(item, splitSlug[0]) != null)
                    outputItem = this.getSlugItem(item, splitSlug[0]).toLocaleString();
                else
                    outputItem = this.getSlugItem(item, splitSlug[0]);

                for (let i = 1; i < splitSlug.length; i++) {
                    if (header.type === 'formattedNumber' && this.getSlugItem(item, splitSlug[i]) != null)
                        outputItem += this.getSlugItem(item, splitSlug[i]).toLocaleString();
                    else
                        outputItem += this.getSlugItem(item, splitSlug[i]);
                }
                return outputItem;
            } catch (_) {
                return null;
            }
        },
        getSlugItem(item, slug) {
            let splitSlug = slug.split('.');

            if (splitSlug.length === 1) {
                return item[slug];
            }
            try {
                let outputItem = item[splitSlug[0]];
                for (let i = 1; i < splitSlug.length; i++) {
                    outputItem = outputItem[splitSlug[i]];
                }
                return outputItem;
            } catch (_) {
                return null;
            }
        }

    },
    mounted() {
        // // Fuse search initialization.
        // this.fuseSearch = new Fuse(this.tableData, {
        //     keys: ["name", "email"],
        //     threshold: 0.3
        // });
    },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
    border: 0;
    margin-left: 20px;
    margin-right: 20px;
}
</style>
